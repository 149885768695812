<template>
    <div class="bxs-form">
        <form
        class="bxs-form--form"
        ref="form"
        role="form"
        :id="name"
        rel="noopener"
        :name="name"
        :aria-label="name"
        :autocomplete="autocomplete"
        novalidate
        @submit.prevent="submit"
        @input="handleInput">
            <slot />
        </form>
    </div>
</template>

<script>
import {
    reactive,
    provide,
    // eslint-disable-next-line
    getCurrentInstance
} from 'vue'

// function parseDotNotation (str, val, obj) {
//     let currentObj = obj
//     const keys = str.split('.')
//     let i
//     const l = Math.max(1, keys.length - 1)
//     let key

//     for (i = 0; i < l; ++i) {
//       key = keys[i]
//       currentObj[key] = currentObj[key] || {}
//       currentObj = currentObj[key]
//     }

//     currentObj[keys[i]] = val
//     delete obj[str]
// }

// Object.expand = (obj) => {
//   for (const key in obj) {
//     if (key.indexOf('.') !== -1) parseDotNotation(key, obj[key], obj)
//   }
//   return obj
// }

export default {
    name: 'bxs-form',
    props: {
        modelValue: {
            type: Boolean,
            required: false,
            default: false
        },
        name: {
            type: String,
            required: false,
            default: 'form-' + Math.random()
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        'hide-footer': {
            type: Boolean,
            required: false,
            default: false
        },
        'submit-text': {
            type: String,
            required: false,
            default: 'salva'
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        autocomplete: {
            type: [String, Boolean],
            required: false,
            default: 'on' // off
        }
    },
    emits: [
        'update:modelValue',
        'submit'
    ],
    setup(props, ctx) {
        // const instance = getCurrentInstance()
        // console.log('form setup instance', instance)
        // console.log('form setup ctx', ctx)

        provide('bxs-form', {
            bind,
            unbind
        })

        const data = reactive({
            validate_components: []
        })

        function bind(component) {
            data.validate_components.push(component)
        }

        function unbind(uid) {
            const index = data.validate_components.findIndex(c => c.uid === uid)

            if (index > -1) {
                data.validate_components.splice(index, 1)
            }
        }

        return {
            validate_components: data.validate_components
        }
    },
    data () {
        return {
            valid: this.modelValue,
            submitable: false,
            oldValues: [],
            newValues: []
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.old_values = this.validate_components.map(i => i.old_value)
        })
    },
    watch: {
        modelValue (newVal) {
            this.valid = newVal
        }
    },
    methods: {
        handleInput (evt) {
            this.newValues = []

            this.validate_components.forEach((child) => {
                this.newValues.push(child.getValue())
            })

            this.submitable = this.newValues.join(',') !== this.oldValues.join(',')

            this.$emit('update:modelValue', { submitable: this.submitable })
        },
        submit () {
            console.log('submit')
            this.valid = this.checkValidate()
            console.log('form is_valid', this.valid)

            if (!this.valid) {
                this.$toast.info('Attenzione ai campi richiesti')
                return false
            }

            const data = {}

            this.validate_components.forEach((child) => {
                data[child.name] = child.getValue()
            })

            console.log(this.name, data)

            this.$emit('submit', data)
        },
        reset () {
            this.validate_components.forEach((child) => {
                child.reset()
            })
        },
        resetValidation () {
            this.validate_components.forEach((child) => {
                child.resetValidation()
            })
        },
        checkValidate () {
            console.log('form validate', this.validate_components)
            let status = true

            this.validate_components.forEach((child) => {
                const check = child.validate().value
                if (!check) status = false
            })

            return status
        }
    },
    beforeUnmount () {
        this.submitable = false
        this.valid = false
        this.oldValues = []
        this.newValues = []
    }
}
</script>

<style lang="scss" scoped>
.bxs-form {
    display: block;
    position: relative;
    width: 100%;
    margin: 0 auto;
}
</style>
