import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import {
    auth,
    checkout,
    checkoutLoan,
    nextFactory
} from './middlewares'

import Error404 from '../views/Err404.vue'
import ErrMaintenance from '../views/ErrMaintenance.vue'

//
import Components from '../views/Components.vue'
import Home from '../views/Home.vue'
import Catalog from '../views/Catalog.vue'
import Product from '../views/Product.vue'
import Search from '../views/Search.vue'
import Regolamento from '../views/Regolamento.vue'
import Scan from '../views/Scan.vue'

import CheckoutLoanProduct from '../views/CheckoutLoanProduct.vue'
import CheckoutRecap from '../views/CheckoutRecap.vue'
import CheckoutCb from '../views/CheckoutCb.vue'
import CheckoutPPCb from '../views/CheckoutPPCb.vue'

// auth
import Signin from '../views/Signin.vue'
import Signup from '../views/Signup.vue'
import PasswordRecovery from '../views/PasswordRecovery.vue'
import PasswordReset from '../views/PasswordReset.vue'

import Me from '../views/Me.vue'
import MeProducts from '../views/MeProducts.vue'
import MeProductEdit from '../views/MeProductEdit.vue'
import MeOrders from '../views/MeOrders.vue'
import MeOrder from '../views/MeOrder.vue'
import MeOrderCreateReview from '../views/MeOrderCreateReview.vue'
import MeRenewal from '../views/MeRenewal.vue'

//
import Help from '../views/Help.vue'
import Support from '../views/Support.vue'
import Policy from '../views/Policy.vue'

const routes = [
    {
        path: '/ui/components',
        name: 'ui_components',
        component: Components
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/errors/404'
    },
    {
        path: '/errors/maintenance',
        name: 'error_maintenance',
        component: ErrMaintenance
    },
    {
        path: '/errors/404',
        name: 'error_404',
        component: Error404
    },
    // --------------------------------------------------------------
    {
        path: '/signin',
        name: 'signin',
        component: Signin,
        alias: ['/login', '/log'],
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/signup',
        name: 'signup',
        component: Signup,
        alias: ['/registrazione', '/registrati'],
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/password/recovery',
        name: 'password_recovery',
        component: PasswordRecovery
    },
    {
        path: '/password/reset',
        name: 'password_reset',
        component: PasswordReset
    },
    // --------------------------------------------------------------
    {
        path: '/',
        name: 'home',
        component: Home,
        alias: ['/home', '/index']
    },
    {
        path: '/catalogs/:slug',
        name: 'catalog',
        component: Catalog
    },
    {
        path: '/products/:id',
        name: 'product',
        component: Product
    },
    {
        path: '/search',
        name: 'search',
        component: Search
    },
    {
        path: '/help',
        name: 'help',
        component: Help
    },
    {
        path: '/support',
        name: 'support',
        component: Support
    },
    {
        path: '/policies',
        name: 'policies',
        component: Policy
    },
    {
        path: '/regolamento',
        name: 'regolamento',
        component: Regolamento
    },
    {
        path: '/scan',
        name: 'scan',
        component: Scan
    },
    // --------------------------------------------------------------
    {
        path: '/me',
        name: 'me',
        component: Me,
        alias: ['/profile'],
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/me/products',
        name: 'me_products',
        component: MeProducts,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/me/product-edit',
        name: 'me_product_edit',
        component: MeProductEdit,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/me/orders',
        name: 'me_orders',
        component: MeOrders,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/me/orders/:id',
        name: 'me_order',
        component: MeOrder,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/me/orders/:id/review',
        name: 'me_order_create_review',
        component: MeOrderCreateReview,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/me/rinnovo-tessera',
        name: 'me_renewal',
        component: MeRenewal,
        meta: {
            middlewares: [auth]
        }
    },
    // --------------------------------------------------------------
    {
        path: '/checkout/loan',
        name: 'checkout_loan',
        component: CheckoutLoanProduct,
        meta: {
            middlewares: [auth, checkout]
        }
    },
    {
        path: '/checkout/recap',
        name: 'checkout_recap',
        component: CheckoutRecap,
        meta: {
            middlewares: [auth, checkout, checkoutLoan]
        }
    },
    {
        path: '/checkout/:id/cb',
        name: 'checkout_cb',
        component: CheckoutCb,
        meta: {
            middlewares: [auth]
        }
    },
    {
        path: '/checkout/pp/cb',
        name: 'checkout_paypal_cb',
        component: CheckoutPPCb
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        console.log('scrollBehavior', savedPosition)
        if (to.name === 'catalog') return savedPosition
        return { top: 0, left: 0 }
    }
})

router.beforeEach((to, from, next) => {
    console.log('> router.beforeEach', !!store.state.access, to.path, to.name)

    store.commit('set_page_enter', to.name)
    store.commit('set_page_leave', from && from.name ? from.name : null)

    if (to.name !== 'signin' && to.name !== 'signup' && to.name !== 'error_maintenance') {
        localStorage.setItem('lel_last_page', to.fullPath)
    }

    if (store.state.library && !store.state.library.is.available) {
        return next('/errors/maintenance')
    }

    if (to.meta.middlewares) {
        const middlewares = Array.isArray(to.meta.middlewares) ? to.meta.middlewares : [to.meta.middlewares]
        const context = { from, to, next }
        const nextMiddleware = nextFactory(context, middlewares, 1)
        const auths = to.meta.authorize ? to.meta.authorize : []
        return middlewares[0](auths, { ...context, next: nextMiddleware })
    }

    return next()
})

export default router
