<template>
    <Teleport to="body">
        <div
        :class="classes"
        :style="styles">
            <bxs-toolbar color="background">
                <slot />
            </bxs-toolbar>
        </div>
    </Teleport>
</template>

<script>
import { toUnit } from '@/assets/libs/utils/string'

export default {
    name: 'bottom_navigation',
    props: {
        bottom: {
            type: [String, Number, Boolean],
            required: false,
            default: false
        },
        borderless: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        classes () {
            return ['bxs-bottom-navigation', {
                'bxs-bottom-navigation-borderless': this.borderless
            }]
        },
        styles () {
            const v = {}
            const bottom = toUnit(this.bottom)
            if (bottom) v.bottom = bottom
            return v
        }
    }
}
</script>

<style lang="scss">
.bxs-bottom-navigation {
    > .bxs-toolbar {
        box-shadow: none !important;
    }
}
</style>

<style lang="scss" scoped>
.bxs-bottom-navigation {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: transparent;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    z-index: var(--layer-navbar);

    > .bxs-toolbar {
        // border top
        // &::before {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: calc(var(--thickness) * 2);
        //     background-color: var(--divider-color);
        // }
    }

    &.bxs-bottom-navigation-borderless {
        border: none;
    }
}
</style>