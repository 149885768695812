import Resource from './BaseResource'

class User extends Resource {
  constructor (config, tools, version, path) {
    super(config, tools, version, path)

    this.me = null
  }

  async getMe (q) {
    const doc = await this.tools.http.get(`${this.config.baseUrl}/v1/me`, q)
    this.me = doc
    return doc
  }
}

export default User
