<template>
    <div class="ui-components-view">
        <bxs-top-nav>UI Comps</bxs-top-nav>

        <!-- --------------------------------------------------------------------
            colors
        -------------------------------------------------------------------- -->
        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p><i>Colors</i></p>
                    <div class="bxs-divider mt-2"></div>
                </div>

                <div class="light">
                    <div class="mb-ui">
                        <div class="leila-ui-comp primary">
                            <small>primary</small>
                        </div>

                        <div class="leila-ui-comp secondary">
                            <small>secondary</small>
                        </div>

                        <div class="leila-ui-comp tertiary">
                            <small>tertiary</small>
                        </div>

                        <div class="leila-ui-comp fourth">
                            <small>fourth</small>
                        </div>

                        <div class="leila-ui-comp highlight">
                            <small>highlight</small>
                        </div>

                        <div class="leila-ui-comp mute">
                            <small>mute</small>
                        </div>

                        <div class="leila-ui-comp disabled">
                            <small>disabled</small>
                        </div>
                    </div>

                    <div class="mb-ui">
                        <div class="leila-ui-comp info">
                            <small>info</small>
                        </div>

                        <div class="leila-ui-comp success">
                            <small>success</small>
                        </div>

                        <div class="leila-ui-comp error">
                            <small>error</small>
                        </div>

                        <div class="leila-ui-comp warn">
                            <small>warn</small>
                        </div>
                    </div>

                    <div class="mb-ui">
                        <div
                        v-for="n in 12"
                        :key="n"
                        :class="['leila-ui-comp', 'greyscale-' + n]">
                            <small>greyscale-{{ n }}</small>
                        </div>
                    </div>

                    <div class="mb-ui">
                        <div class="leila-ui-comp fb">
                            <small>fb</small>
                        </div>

                        <div class="leila-ui-comp yt">
                            <small>yt</small>
                        </div>

                        <div class="leila-ui-comp ig">
                            <small>ig</small>
                        </div>

                        <div class="leila-ui-comp tw">
                            <small>tw</small>
                        </div>

                        <div class="leila-ui-comp li">
                            <small>li</small>
                        </div>
                    </div>
                </div>
            </bxs-layout>
        </section>

        <!-- --------------------------------------------------------------------
            typo
        -------------------------------------------------------------------- -->

        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p><i>Typo</i></p>
                    <div class="bxs-divider mt-2"></div>
                </div>

                <div class="mb-ui">
                    <h1>Lorem ipsum</h1>
                    <h2>Lorem ipsum</h2>
                    <h3>Lorem ipsum</h3>
                    <h4>Lorem ipsum</h4>
                    <h5>Lorem ipsum</h5>
                    <h6>Lorem ipsum</h6>
                </div>

                <div>
                    <p class="mb-2">Lorem ipsum dolor, sit amet consectetur adipisicing elit. <router-link to="/">Quo commodi</router-link>, nam voluptate quisquam fuga inventore!</p>
                    <p class="mb-2">Lorem ipsum dolor sit amet, <b>consectetur adipisicing elit</b>. Dolore quos magni dolorum voluptatum fugiat blanditiis cupiditate quam.</p>
                    <p class="mb-2">Lorem ipsum <u>dolor sit amet</u> consectetur adipisicing elit. Quae totam facere, reprehenderit distinctio laboriosam dolorem, harum <i>debitis asperiores beatae dicta fugit tempore</i>.</p>
                    <small>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis doloribus libero, blanditiis dolore eos repudiandae distinctio nemo explicabo corporis id eligendi rem aliquid adipisci quos sed porro quaerat labore quia.</small>
                </div>
            </bxs-layout>
        </section>

        <!-- --------------------------------------------------------------------
            actions
        -------------------------------------------------------------------- -->

        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p><i>Actions</i></p>
                    <div class="bxs-divider mt-2"></div>
                </div>

                <div class="mb-ui">
                    <router-link to="/" class="mr-6">Your int link</router-link>
                    <a href="https://leila-bologna.it">Your _blank link</a>
                </div>

                <div class="mb-ui">
                    <bxs-btn class="mr-2 mb-2">Your base button</bxs-btn>

                    <bxs-btn
                    color="primary"
                    light
                    class="mr-2 mb-2">Your base colored button</bxs-btn>

                    <bxs-btn
                    color="secondary"
                    class="mr-2 mb-2">Your base colored button</bxs-btn>

                    <bxs-btn text class="mr-2 mb-2">Your text button</bxs-btn>
                    <bxs-btn pill class="mr-2 mb-2">Your Pill button</bxs-btn>
                    <bxs-btn outlined class="mr-2 mb-2">Your Outlined button</bxs-btn>

                    <bxs-btn icon class="mr-2 mb-2">
                        <bxs-icon name="envelope"></bxs-icon>
                    </bxs-btn>

                    <bxs-btn icon color="info" class="mr-2 mb-2">
                        <bxs-icon name="envelope"></bxs-icon>
                    </bxs-btn>

                    <bxs-btn fab class="mr-2 mb-2">
                        <bxs-icon name="envelope"></bxs-icon>
                    </bxs-btn>

                    <bxs-btn fab color="black" light class="mr-2 mb-2">
                        <bxs-icon name="envelope"></bxs-icon>
                    </bxs-btn>

                    <bxs-btn
                    append-icon="envelope"
                    prepend-icon="envelope"
                    class="mr-2 mb-2">Your icon button</bxs-btn>

                    <bxs-btn
                    outlined
                    append-icon="envelope"
                    prepend-icon="envelope"
                    class="mr-2 mb-2">Your outlined icon button</bxs-btn>

                    <bxs-btn
                    block
                    between
                    append-icon="chevron-right"
                    class="mr-2">Your block base button</bxs-btn>
                </div>

                <div class="mb-ui">
                    <bxs-carousel
                    :items="products_categories"
                    freemode
                    class="mb-ui">
                        <template #item="{ item }">
                            <bxs-chip>{{ item.name }}</bxs-chip>
                        </template>
                    </bxs-carousel>

                    <bxs-carousel
                    :items="products_categories"
                    freemode
                    class="mb-ui">
                        <template #item="{ item }">
                            <bxs-chip outlined>{{ item.name }}</bxs-chip>
                        </template>
                    </bxs-carousel>

                    <bxs-carousel
                    :items="products_categories"
                    freemode>
                        <template #item="{ item }">
                            <bxs-chip to="/">{{ item.name }}</bxs-chip>
                        </template>
                    </bxs-carousel>
                </div>

                <div class="mb-ui">
                    <bxs-list>
                        <bxs-list-item
                        to="/signin">
                            <p>Signin</p>
                            <small class="text-mute">List item 1</small>
                        </bxs-list-item>

                        <bxs-list-item
                        append-icon="chevron-right"
                        spacer
                        to="/password/recovery">Recupero password</bxs-list-item>

                        <bxs-list-item
                        append-icon="chevron-right"
                        spacer
                        to="/">List item 3</bxs-list-item>
                    </bxs-list>
                </div>

                <div>
                    <bxs-alert class="mb-ui">Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe quia ipsa voluptas praesentium libero, voluptatum blanditiis nobis optio laudantium amet facere quod impedit, quisquam atque ipsum minima reiciendis, ipsam quis.</bxs-alert>
                    <bxs-alert type="warn" class="mb-ui">Lorem ipsum dolor sit amet consectetur adipisicing elit.</bxs-alert>
                    <bxs-alert type="error" class="mb-ui">Lorem ipsum dolor sit amet consectetur adipisicing elit.</bxs-alert>
                </div>
            </bxs-layout>
        </section>

        <!-- --------------------------------------------------------------------
            form
        -------------------------------------------------------------------- -->

        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p><i>Form</i></p>
                    <div class="bxs-divider mt-2"></div>
                </div>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <bxs-title>Hai delle domande?</bxs-title>
                    <p>Specifica tutto in questo spazio, ti risponderemo appena possibile.</p>
                </div>

                <div>
                    <bxs-textarea-field
                    solo
                    label="Inserisci qui le tue domande!"
                    name="note" />
                </div>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <bxs-text-field
                hide-form
                clearable
                name="keywords"
                label="Cosa stai cercando?">
                    <template #prepend>
                        <bxs-icon
                        name="search"
                        width="1.25em"></bxs-icon>
                    </template>
                </bxs-text-field>
            </bxs-layout>
        </section>

        <!-- --------------------------------------------------------------------
            single comps
        -------------------------------------------------------------------- -->

        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p><i>Single comps</i></p>
                    <div class="bxs-divider mt-2"></div>
                </div>

                <bxs-btn
                color="primary"
                light
                min-height="60"
                block
                uppercase
                class="mb-ui">Prendi in prestito</bxs-btn>

                <bxs-alert
                type="info"
                class="mb-ui">Oggetto in prestito fino al <span class="text-700">23-23-1232</span></bxs-alert>

                <bxs-btn
                min-height="60"
                color="primary"
                light
                block
                uppercase
                class="mb-ui">Avvisami quando rientra</bxs-btn>

                <bxs-alert class="mb-ui">Sei in lista d'attesa, ti avviseremo quando l'oggetto rientrerà!</bxs-alert>

                <bxs-alert
                type="warn"
                class="mb-ui">Al momento non pui prendere in prestito oggetti, <router-link to="/me">verifica il tuo stato</router-link></bxs-alert>

                <bxs-alert
                type="info"
                class="mb-ui">Oggetto in manutenzione</bxs-alert>

                <to-products-section />

                <help-section />

                <signin-section />

                <pressing-section />

                <section>
                    <bxs-layout>
                        <bxs-btn
                        append-icon="close"
                        between
                        block
                        color="card">Pulisci il checkout e riparti</bxs-btn>
                    </bxs-layout>
                </section>

                <product-row :item="{
                    id: '133006000000084971',
                    imgs: [],
                    loan: {
                        max_months: 1,
                        preparation_days: 2,
                        status: 'available',
                        to_date: new Date(),
                        status: 'busy'
                    },
                    name: 'Bilancia per neonati'
                }"
                class="mb-6" />

                <order-row class="mb-6"></order-row>

                <headquarter-row
                :item="{
                    additional_days: 0,
                    address: '',
                    id: '133006000000027023',
                    img: { alt: null, url: null },
                    name: 'Biblioteca Salaborsa',
                    opening_days: ['Tuesday'],
                    opening_times: '16.00 - 19.00'
                }"
                class="mb-6 bxs-pointer">
                    <template #actions>
                        <bxs-icon
                        text-color="primary"
                        width="20"
                        height="20">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </bxs-icon>
                    </template>
                </headquarter-row>

                <div class="flex align-center mb-ui">
                    <bxs-chip
                    width="100%"
                    tile>10-12-2043</bxs-chip>

                    <bxs-spacer />

                    <bxs-icon
                    width="60"
                    name="arrow-right"
                    class="mx-4"></bxs-icon>

                    <bxs-spacer />

                    <bxs-chip
                    width="100%"
                    tile>10-12-2043</bxs-chip>
                </div>

                <div class="text-center bxs-truncate mb-ui">
                    <h3 class="text-ui text-500 mb-0 text-none-transform">Simone Fusco</h3>
                    <p class="text-mute">simon3fusco@gmail.com</p>
                </div>

                <div class="mb-ui">
                    <div class="text-center mb-2">
                        <bxs-chip class="mr-2">
                            <span class="text-500">Tessera attiva</span>
                            <bxs-icon
                            name="check"
                            class="ml-3" />
                        </bxs-chip>

                        <bxs-chip outlined class="text-primary">
                            <span class="text-500">Tessera scaduta</span>
                        </bxs-chip>
                    </div>

                    <ul class="flex justify-center text-center">
                        <li class="text-center mr-12">
                            <h5 class="mb-0">10</h5>
                            <small>Condivisi</small>
                        </li>

                        <li class="text-center mr-12">
                            <h5 class="mb-0">2</h5>
                            <small>In prestito</small>
                        </li>

                        <li class="text-center">
                            <h5 class="mb-0">4</h5>
                            <small>Disponibili</small>
                        </li>
                    </ul>
                </div>

                <div class="mb-ui">
                    <bxs-title>Contatti</bxs-title>

                    <bxs-list>
                        <bxs-list-item
                        spacer
                        append-icon="telephone-fill">333 333 333 333</bxs-list-item>

                        <bxs-list-item
                        spacer
                        append-icon="envelope">simon3fusco@gmail.com</bxs-list-item>
                    </bxs-list>
                </div>

                <div class="mb-ui">
                    <h5 class="mb-ui">Link utili</h5>

                    <bxs-list>
                        <bxs-list-item
                        append-icon="arrow-top-right"
                        spacer>Visita il nostro sito</bxs-list-item>

                        <bxs-list-item
                        href="https://leila-bologna.it/tessera"
                        spacer
                        append-icon="arrow-up-right">Iscrizioni</bxs-list-item>

                        <bxs-list-item
                        href="https://leila-bologna.it/regolamento"
                        spacer
                        append-icon="arrow-up-right">Prestiti Policy</bxs-list-item>

                        <bxs-list-item
                        href="https://leila-bologna.it/contatti"
                        spacer
                        append-icon="arrow-up-right">Contatti e assistenza</bxs-list-item>
                    </bxs-list>
                </div>

                <div>
                    <h5 class="mb-ui">Community</h5>

                    <bxs-list>
                        <!-- <bxs-list-item
                        href="https://leila-bologna.it"
                        spacer
                        append-icon="arrow-up-right">Sviluppatori</bxs-list-item> -->

                        <bxs-list-item
                        href="https://leila-bologna.it"
                        spacer
                        append-icon="arrow-up-right">Collaborazioni</bxs-list-item>

                        <bxs-list-item
                        href="https://leila-bologna.it"
                        spacer
                        append-icon="arrow-up-right">Pubblicità</bxs-list-item>

                        <!-- <bxs-list-item
                        href="https://leila-bologna.it"
                        spacer
                        append-icon="arrow-up-right">Investitori</bxs-list-item> -->

                        <!-- <bxs-list-item
                        href="https://leila-bologna.it"
                        spacer
                        append-icon="arrow-up-right">Venditori</bxs-list-item> -->
                    </bxs-list>
                </div>
            </bxs-layout>
        </section>

        <!-- --------------------------------------------------------------------
            sections
        -------------------------------------------------------------------- -->

        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <p><i>Sections</i></p>
                    <div class="bxs-divider mt-2"></div>
                </div>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <div class="flex align-end nowrap">
                    <h1 class="h2 text-uppercase text-700 flex-1">
                        <span class="text-primary">Abbiamo bisogno</span>
                        <br>
                        <span class="text-primary">di utilizzare,</span>
                        <br>
                        <span class="text-outlined text-overline">non di possedere.</span>
                    </h1>
                </div>
            </bxs-layout>
        </section>

        <categories-2-section />

        <highlighted-products-section />

        <headquarters-section />

        <!-- --------------------------------------------------------------------
            footer
        -------------------------------------------------------------------- -->

        <section>
            <bxs-layout>
                <div class="bxs-divider mb-ui"></div>

                <bxs-footer />
            </bxs-layout>
        </section>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ui_components',
    computed: {
        ...mapState({
            products_categories: state => state.products_categories
        })
    }
}
</script>