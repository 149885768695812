import Resource from './BaseResource'
// import { assign } from 'lodash'

class Checkout extends Resource {
    constructor(config, tools, version, path) {
        super(config, tools, version, path)

        this.data = {
            id: null,
            origin_id: null,
            status: 'checkout',
            product: {
                id: null,
                code: null,
                name: null,
                location: {
                    address: null,
                    position: null
                },
                img: {
                    url: null
                }
            },
            customer: {
                id: null,
                name: null,
                card_number: null,
                email: null
            },
            shipping: {
                method: 'pickup',
                address: {
                    id: null,
                    name: null,
                    address: null
                },
                date: null
            },
            loan: {
                from_date: null,
                to_date: null,
                dates: [],
                no_of_days: null
            },
            note: null
        }
    }

    // getters

    get exists() {
        return !!this.data.id
    }

    get is_set_loan() {
        return !!this.loan.from_date && !!this.loan.to_date
    }

    get status() {
        return this.data.status
    }

    get product() {
        if (!this.data.product.id) return null
        return this.data.product
    }

    get loan() {
        return this.data.loan
    }

    get shipping() {
        return this.data.shipping
    }

    get note () {
        return this.data.note
    }

    set note (v) {
        this.data.note = v
    }

    // publics

    async get() {
        const res = await this.tools.http.get(`${this.api}`)
        if (res) {
            this.data = res
        } else {
            this.data = {
                id: null,
                origin_id: null,
                status: 'checkout',
                product: {
                    id: null,
                    code: null,
                    name: null,
                    location: {
                        address: null,
                        position: null
                    },
                    img: {
                        url: null
                    }
                },
                customer: {
                    id: null,
                    name: null,
                    card_number: null,
                    email: null
                },
                shipping: {
                    method: 'pickup',
                    address: {
                        id: null,
                        name: null,
                        address: null
                    },
                    date: null
                },
                loan: {
                    from_date: null,
                    to_date: null,
                    dates: [],
                    no_of_days: null
                },
                note: null
            }
        }

        return res
    }

    async addToCart(product_id) {
        const res = await this.tools.http.post(`${this.api}/cart/` + product_id)
        this.data = res
        return res
    }

    async setShippng(method, address_id, shipping_geo) {
        const res = await this.tools.http.post(
            `${this.api}/shipping`,
            { method, address_id, shipping_geo }
        )
        this.data.loan = res.loan
        this.data.shipping = res.shipping
        return res
    }

    async setLoanDate(from_date, to_date, note) {
        const res = await this.tools.http.post(
            `${this.api}/loan`,
            { from_date, to_date, note }
        )
        this.data.loan = res.loan
        return res
    }

    async clear() {
        const res = await this.tools.http.delete(`${this.api}/cart/`)
        this.data = {
            id: null,
            origin_id: null,
            status: 'checkout',
            product: {
                id: null,
                code: null,
                name: null,
                location: {
                    address: null,
                    position: null
                },
                img: {
                    url: null
                }
            },
            customer: {
                id: null,
                name: null,
                card_number: null,
                email: null
            },
            shipping: {
                method: 'pickup',
                address: {
                    id: null,
                    name: null,
                    address: null
                },
                date: null
            },
            loan: {
                from_date: null,
                to_date: null,
                dates: [],
                no_of_days: null
            },
            note: null
        }
        return res
    }

    async confirm(body) {
        const res = await this.tools.http.put(`${this.api}/confirm`, body)
        this.data = res
        return res
    }
}

export default Checkout
