<template>
    <div class="support-view">
        <bxs-top-nav>Supporto</bxs-top-nav>

        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <bxs-title>Hai bisogno di supporto?</bxs-title>
                    <p>Compila i campi e contattaci!</p>
                </div>

                <bxs-form
                ref="form"
                @submit="submit">
                    <bxs-text-field
                    v-model="model.email"
                    label="La tua email"
                    name="email"
                    required
                    type="email"
                    class="mb-1" />

                    <bxs-text-field
                    v-model="model.object"
                    label="Oggetto"
                    name="object"
                    required
                    class="mb-1" />

                    <bxs-text-field
                    v-model="model.section"
                    label="Sezione"
                    name="section"
                    class="mb-1" />

                    <bxs-textarea-field
                    v-model="model.message"
                    label="* Il tuo messaggio"
                    name="message"
                    required />

                    <bxs-btn
                    block
                    :disabled="loading"
                    :loading="loading"
                    color="primary"
                    light
                    @click="$refs.form.submit()">Invia</bxs-btn>
                </bxs-form>

                <bxs-alert
                v-if="error"
                type="error"
                class="mt-ui">{{ error }}</bxs-alert>

                <bxs-alert
                v-if="completed"
                type="info"
                class="mt-ui">Grazie, ti ricontatteremo il prima possibile!</bxs-alert>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'support',
    data () {
        return {
            loading: false,
            completed: false,
            error: null,
            model: {
                email: null,
                object: null,
                section: null,
                message: null
            }
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        start () {
            if (this.$leila.auth.me) {
                this.model.email = JSON.parse(JSON.stringify(this.$leila.auth.me.email))
            }
        },
        async submit () {
            this.error = null
            this.completed = null

            if (!this.model.message) {
                return this.$toast.info('Attenzione ai campi richiesti')
            }

            this.loading = true

            try {
                await this.$leila.supports.create(this.model)
                this.completed = true

                // this.model = {
                // object: null,
                // section: null,
                // message: null
                // }
            } catch (err) {
                this.error = err.message || err.name || err
            } finally {
                this.loading = false
            }
        }
    }
}
</script>