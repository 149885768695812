<template>
    <div class="me-products-view">
        <bxs-top-nav>
            I tuoi oggetti

            <template #append>
                <bxs-menu
                v-if="$leila.env === 'development'"
                icon>
                    <bxs-list>
                        <bxs-list-item
                        to="/me/product-edit"
                        prepend-icon="plus">Carica il tuo oggetto</bxs-list-item>
                    </bxs-list>
                </bxs-menu>
            </template>
        </bxs-top-nav>

        <bxs-data-lazy
        v-model="data_page"
        :call="() => $leila.me.getProducts()">
            <section>
                <bxs-layout>
                    <div v-if="!data_page.length">
                        <div class="text-center mb-ui">
                            <h3>Ancora nessun oggetto</h3>
                            <p>In questa sezione troverai lo storico dei tuoi oggetti</p>
                        </div>
                    </div>

                    <ul v-else>
                        <li
                        v-for="item in data_page"
                        :key="item.id"
                        class="mb-ui">
                            <product-row :item="item" />
                        </li>
                    </ul>

                    <div
                    v-if="$leila.env === 'development'"
                    class="text-center">
                        <bxs-btn
                        color="primary"
                        light
                        to="/me/product-edit">Carica il tuo oggetto!</bxs-btn>
                    </div>
                </bxs-layout>
            </section>
        </bxs-data-lazy>

        <!-- categories -->
        <categories-2-section />

        <!-- checkoutbar -->
        <checkoutbar to="/checkout/loan" />
    </div>
</template>

<script>
export default {
    name: 'me_products',
    data () {
        return {
            data_page: null
        }
    }
}
</script>