<template>
    <div class="me-renewal--view">
        <bxs-top-nav>Rinnovo tessera</bxs-top-nav>

        <section>
            <bxs-layout>
                <bxs-loader
                v-if="loading"
                class="mb-ui"></bxs-loader>

                <div>
                    <div
                    v-if="!loading"
                    class="mb-ui">
                        <bxs-card class="pa-ui">
                            <h3>Rinnova la tua tessera a {{ card.Prezzo }} €</h3>
                        </bxs-card>
                    </div>

                    <div
                    id="paypal-button-container"
                    style="width: 100%;"></div>
                </div>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
// "buyer": {
//     "email": "sb-uxcap26022810_api1.business.example.com",
//     "password": "325PEPJYCM44YLR7"
// },
// "customer": {
//     "email": "sb-rnvio26284664@personal.example.com",
//     "password": "+W)ZL0}v"
// }

export default {
    name: 'me_renewal',
    data () {
        return {
            card: null,
            loading: true,
            error: null
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        async start () {
            try {
                this.card = await this.$leila.libraries.getRenewalsCards()

                await this.paypal().load()
                this.paypal().init()
            } catch (err) {
                this.error = err
            } finally {
                this.loading = false
            }
        },
        paypal () {
            return {
                load: () => {
                    console.log('load paypal')

                    return new Promise((resolve, reject) => {
                        const script = document.createElement('script')
                        script.setAttribute('data-sdk-integration-source', 'button-factory')
                        const pk = this.$store.state.library.pp_pk // 'AcvcIXorz3s7nW2cE_BnIoiHbPbLzy-jbfvwFOXjTqJ0HQUS7PyzhzqNhsWpnMB6IBcI9ZkhVAZrVbwI'
                        // const query_components = '&components=buttons'
                        const query = '?client-id=' + pk + '&currency=EUR&disable-funding=card,credit,bancontact,blik,eps,giropay,ideal,mybank,p24,sepa,sofort,venmo'
                        script.src = 'https://www.paypal.com/sdk/js' + query
                        // data-sdk-integration-source="button-factory"
                        // script.setAttribute('data-sdk-integration-source', 'button-factory')
                        script.addEventListener('load', resolve)
                        document.body.appendChild(script)
                    })
                },
                init: () => {
                    // eslint-disable-next-line
                    return paypal.Buttons({
                        style: {
                            shape: 'rect',
                            color: 'blue',
                            layout: 'vertical',
                            label: 'paypal'
                        },
                        onClick: async (data, actions) => {
                            this.$store.commit('set_is_loading', true)
                            console.log('paypal onClick()', data)
                            this.error = null

                            try {
                                await this.$leila.post('v1/checkout/intent')
                            } catch (err) {
                                this.$store.commit('set_is_loading', false)
                                console.log('paypal onClick() error', err)
                                this.error = err
                                return actions.reject()
                            }
                        },
                        createOrder: (data, actions) => {
                            console.log('paypal createOrder()', data)

                            return actions.order.create({
                                purchase_units: [
                                    {
                                        amount: {
                                            currency_code: 'EUR',
                                            value: this.$store.state.library.delivery.price
                                        }
                                    }
                                ]
                            })
                        },
                        onCancel: (data, actions) => {
                            console.log('paypal onCancel()', data)
                        },
                        onApprove: (data, actions) => {
                            console.log('paypal onApprove()', data, actions)

                            return actions.order.capture().then((d) => {
                                console.log('paypal onApprove().order.capture()', d)
                                // this.confirm(data)
                            }).catch((err) => {
                                this.error = err
                                console.log('paypal onApprove() order.capture() error', err)
                            })
                        },
                        //
                        payment: (data, actions) => {
                            console.log('paypal payment()', data)
                        },
                        onAuthorize: (data, actions) => {
                            console.log('paypal onAuthorize()', data)
                        },
                        onError: (err) => {
                            this.$store.commit('set_is_loading', false)
                            this.error = err
                            console.log('paypal onError()', err)
                        }
                    }).render('#paypal-button-container')
                }
            }
        }
    }
}
</script>