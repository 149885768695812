<template>
    <bxs-data-lazy
    v-model="headquarters"
    :call="() => $leila.get('v1/headquarters')">
        <section>
            <bxs-layout class="mb-ui">
                <bxs-title>Punti di consegna e ritiro</bxs-title>
            </bxs-layout>

            <bxs-layout>
                <!-- <bxs-carousel
                :items="headquarters"
                :items-space-between="0">
                    <template #item="{ item }">
                        <bxs-card
                        width="300"
                        no-elevation
                        class="pa-ui mx-2">
                            <headquarter-row
                            :key="item.id"
                            :item="item" />
                        </bxs-card>
                    </template>
                </bxs-carousel> -->

                <ul>
                    <li
                    v-for="(item, i) in headquarters"
                    :key="item.id">
                        <headquarter-row
                        :item="item"
                        class="mb-ui mb-0-last" />

                        <hr
                        v-if="i < headquarters.length - 1"
                        class="mb-ui">
                    </li>
                </ul>

                <img
                src="/img/leila-circle-blue.png"
                alt=""
                class="img-ux img-ux-top-right">
            </bxs-layout>
        </section>
    </bxs-data-lazy>
</template>

<script>
export default {
    name: 'headquarters-section',
    data () {
        return {
            headquarters: []
        }
    }
}
</script>

<style lang="scss" scoped>
.img-ux {
    position: absolute;
    z-index: -1;
    width: 60px;
    opacity: 1;
    transform: rotate(90deg);
}

.img-ux-top-right {
    top: -60px;
    left: -30px;
}
</style>