<template>
    <section v-if="highlighted_products.length > 0">
        <bxs-layout>
            <div class="flex align-center mb-ui">
                <bxs-title>Oggetti in evidenza</bxs-title>

                <bxs-spacer></bxs-spacer>

                <router-link
                to="/catalogs/all"
                class="text-uppercase">
                    <small>Vedi tutti</small>
                    <!-- <bxs-icon name="chevron-right"></bxs-icon> -->
                </router-link>
            </div>

            <bxs-carousel
            v-for="(items, i) in comp_highlighted_products"
            :key="i"
            :items="items"
            class="mb-ui">
                <template #item="{ item }">
                    <bxs-figure
                    ratio="1"
                    width="120"
                    :src="item.imgs.length > 0 ? $filters.toStaticFile(item.imgs[0]) : null"
                    class="bxs-pointer"
                    @click="$router.push(`/products/${item.id}`).catch(() => {})">
                        <div
                        v-if="item.loan.status === 'busy'"
                        class="badge-in-prestito">
                            <img
                            src="/img/icon-in-prestito.svg"
                            alt="">
                        </div>
                    </bxs-figure>
                </template>
            </bxs-carousel>

            <!-- <bxs-row>
                <bxs-col
                v-for="(item, i) in highlighted_products"
                :key="i"
                cols="2">
                    <bxs-figure
                    ratio="1"
                    :src="item.imgs.length > 0 ? $filters.toStaticFile(item.imgs[0]) : null"
                    class="bxs-pointer"
                    @click="$router.push(`/products/${item.id}`).catch(() => {})">
                        <div class="badge-in-prestito">
                            <img
                            src="/img/icon-in-prestito.svg"
                            alt="">
                        </div>
                    </bxs-figure>
                </bxs-col>
            </bxs-row> -->

            <img
            src="/img/leila-circle-blue.png"
            alt=""
            class="img-ux img-ux-top-right">
        </bxs-layout>
    </section>
</template>

<script>
import { mapState } from 'vuex'

function splitArray (array, maxElements) {
    // const lunghezzaParte = Math.floor(array.length / numVolte)
    // const partiSeparate = []
    // let indice = 0

    // for (let i = 0; i < numVolte; i++) {
    //     partiSeparate.push(array.slice(indice, indice + lunghezzaParte))
    //     indice += lunghezzaParte
    // }

    // return partiSeparate

    if (!Array.isArray(array)) {
        throw new Error('Il primo argomento deve essere un array')
    }

    if (maxElements <= 0 || !Number.isInteger(maxElements)) {
        throw new Error('maxElements deve essere un intero maggiore di 0')
    }

    const result = []
    for (let i = 0; i < array.length; i += maxElements) {
        result.push(array.slice(i, i + maxElements))
    }

    return result
}

export default {
    name: 'highlighted_products_section',
    computed: {
        ...mapState({
            highlighted_products: state => state.highlighted_products,
            comp_highlighted_products: state => splitArray(state.highlighted_products, 8)
        })
    }
}
</script>

<style lang="scss" scoped>
.img-ux {
    position: absolute;
    z-index: -1;
    width: 60px;
    opacity: 1;
    transform: rotate(90deg);
}

.img-ux-top-right {
    top: -30px;
    right: -30px;
}
</style>