// import { isCssColor } from '@/assets/utils/color'

export default {
    props: {
        color: {
            type: [String, Boolean],
            required: false,
            default: false
        },
        'text-color': {
            type: [String, Boolean],
            required: false,
            default: false
        },
        light: {
            type: Boolean,
            required: false,
            default: false
        },
        dark: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        colorable() {
            const v = {
                classes: {
                    dark: this.dark,
                    light: this.light
                },
                styles: {}
            }

            if (this.color) {
                v.classes[this.color] = true
                // if (!isCssColor(this.color)) v.classes[this.color] = true
                // else v.styles.backgroundColor = this.color
            }

            // || (this.$props.text && this.text)
            if (this.textColor) {
                v.classes['text-' + (this.textColor || this.color)] = true
                // if (!isCssColor(this.textColor)) v.classes['text-' + (this.textColor || this.color)] = true
                // else v.styles.color = this.textColor
            }

            return v
        }
    }
}
