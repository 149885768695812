<template>
    <div class="me-product-edito-view">
        <bxs-top-nav>
            Carica il mio oggetto

            <template #append>
                <bxs-menu icon>
                    <bxs-list>
                        <bxs-list-item prepend-icon="edit">Salva ed esci</bxs-list-item>

                        <bxs-list-item prepend-icon="chevron-left">Esci</bxs-list-item>

                        <bxs-list-item prepend-icon="plus">Carica nuovo oggetti</bxs-list-item>
                    </bxs-list>
                </bxs-menu>
            </template>
        </bxs-top-nav>

        <section>
            <bxs-layout>
                <bxs-form
                ref="form"
                @submit="save">
                    <bxs-stepper
                    ref="stepper"
                    v-model="step"
                    :items="steps"
                    @change:before="$refs.form.checkValidate()"
                    @change:after="$refs.form.resetValidation()">
                        <template #item.base>
                            <div class="mb-ui">
                                <p>Informazioni base</p>
                            </div>

                            <bxs-row>
                                <bxs-col cols="12">
                                    <bxs-text-field
                                    v-model="model.title"
                                    label="Nome oggetto"
                                    name="title"
                                    required />
                                </bxs-col>

                                <bxs-col cols="12">
                                    <div class="mb-2 text-mute">
                                        <small>Scegli la categoria dell'oggetto</small>
                                    </div>

                                    <bxs-carousel
                                    :items="categories"
                                    freemode
                                    scrollbar>
                                        <template #item="{ item }">
                                            <bxs-chip
                                            link
                                            :outlined="model.category === item.origin_id"
                                            :color="model.category === item.origin_id ? 'secondary' : null"
                                            @click="model.category = item.origin_id">{{ item.name }}</bxs-chip>
                                        </template>
                                    </bxs-carousel>
                                </bxs-col>
                            </bxs-row>
                        </template>

                        <template #item.description>
                            <div class="mb-ui">
                                <p>Descrizione oggetto</p>
                            </div>

                            <bxs-row>
                                <bxs-col cols="12">
                                    <bxs-textarea-field
                                    v-model="model.description"
                                    label="Descrizione oggetto"
                                    readonly
                                    name="description"
                                    required />
                                </bxs-col>
                            </bxs-row>
                        </template>

                        <template #item.imgs>
                            <div class="mb-ui">
                                <p>Immagini oggetto</p>
                            </div>

                            <bxs-row>
                                <bxs-col cols="12">
                                    <bxs-file-uploader
                                    v-model="model.imgs"
                                    multiple
                                    :immediate="false" />
                                </bxs-col>
                            </bxs-row>
                        </template>

                        <template #item.recap>
                            <div class="mb-ui">
                                <p>Riepilogo del tuo oggetto</p>
                            </div>

                            <div>
                                <div v-if="model.imgs.length > 0">
                                    <bxs-carousel
                                    :items="model.imgs"
                                    freemode
                                    scrollbar>
                                        <template #item="{ item }">
                                            <bxs-figure
                                            :src="item.url || item.base64"
                                            width="120"
                                            ratio="1" />
                                        </template>
                                    </bxs-carousel>
                                </div>
                                <hr class="my-2">
                                <div>
                                    <h6>Titolo</h6>
                                    <p>{{ model.title }}</p>
                                </div>
                                <hr class="my-2">
                                <div>
                                    <h6>Categoria</h6>
                                    <p>{{ selected_category ? selected_category.name : '' }}</p>
                                </div>
                                <hr class="my-2">
                                <div>
                                    <h6>Descrizione</h6>
                                    <p>{{ model.description }}</p>
                                </div>
                                <hr class="my-2">
                                <div>
                                    <bxs-btn
                                    color="primary"
                                    block
                                    light
                                    @click="save">Carica il tuo oggetto!</bxs-btn>
                                </div>

                                <bxs-alert
                                v-if="error"
                                class="mt-ui">{{ error.message }}</bxs-alert>
                            </div>
                        </template>
                    </bxs-stepper>
                </bxs-form>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'me_product_edit',
    data () {
        return {
            is_preloaded: false,
            is_loading: false,
            step: 0,
            steps: [
                { text: 'Informazioni base', value: 'base' },
                { text: 'Descrizione', value: 'description' },
                { text: 'Immagini', value: 'imgs' },
                { text: 'Riepilogo', value: 'recap' }
            ],
            model: {
                title: null,
                description: null,
                category: null,
                imgs: []
            },
            error: null
        }
    },
    computed: {
        ...mapState({
            categories: state => state.products_categories.filter(i => i.slug !== 'in-prestito')
        }),
        selected_category () {
            if (!this.model.category) return
            return this.categories.find(i => i.origin_id === this.model.category)
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        start () {
            // try {
            // } catch (err) {
            // }
            if (this.$leila.env === 'development') {
                this.model.title = 'Oggetto test da webapp'
                this.model.description = 'lorem ipsumlorem ipsumlorem ipsumlorem ipsum'
                this.model.category = this.categories[0].origin_id
            }
        },
        async save () {
            this.is_loading = true
            this.error = null

            try {
                await this.$leila.me.createProduct({
                    ...this.model,
                    category: this.selected_category
                })

                this.$toast.success('Oggetto caricato con successo!')
            } catch (err) {
                console.error(err)
                this.error = err
            } finally {
                this.is_loading = false
            }
        }
    }
}
</script>