<template>
    <div :class="['bxs-alert', {
        ['bxs-alert-' + type]: type
    }]"
    @click="on = true">
        <bxs-icon
        v-if="icon_name"
        width="1.25em"
        height="1.25em"
        :name="icon_name" />

        <div class="flex-1">
            <slot />
        </div>

        <bxs-menu
        v-if="$slots.details"
        v-model="on"
        title="Avviso"
        solo>
            <div class="pa-layout">
                <slot name="details" />
            </div>
        </bxs-menu>
    </div>
</template>

<script>
export default {
    name: 'alert',
    props: {
        type: {
            type: String,
            required: false,
            default: 'info'
        }
    },
    data () {
        return {
            on: false
        }
    },
    computed: {
        icon_name () {
            let v = ''

            if (this.type === 'info') v = 'info-circle-fill'
            else if (this.type === 'warn' || this.type === 'warning') v = 'exclamation-triangle-fill'
            else if (this.type === 'error') v = 'exclamation-lg'

            return v
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/conf.scss';

.bxs-alert {
    display: block;
    border-left: var(--alert-border-left-width) solid transparent;
    border-radius: var(--radius);
    padding: 0.75rem 1rem;
    font-weight: var(--alert-font-weight);
    font-size: 1rem;
    min-height: var(--alert-min-height);
    display: flex;
    flex-flow: row nowrap;

    &.bxs-alert-info {
        border-left-color: darken(map-get($colors, 'info'), 25%);
        background-color: lighten(map-get($colors, 'info'), 50%);
        color: darken(map-get($colors, 'info'), 25%);
    }

    &.bxs-alert-warn,
    &.bxs-alert-warning {
        background-color: lighten(map-get($colors, 'warn'), 25%);
        color: darken(map-get($colors, 'warn'), 25%);
        border-left-color: darken(map-get($colors, 'warn'), 10%);
    }

    &.bxs-alert-error {
        background-color: lighten(map-get($colors, 'error'), 55%);
        border-left-color: darken(map-get($colors, 'error'), 25%);
        color: darken(map-get($colors, 'error'), 25%);
    }

    > .bxs-icon {
        margin-right: var(--alert-icon-margin-x);
    }

    p {
        margin: 0;
        padding: 0;
    }
}
</style>