<template>
    <div class="error-maintenance-view">
        <section class="py-ui">
            <bxs-layout class="text-center">
                <bxs-figure
                src="/img/maintenance.webp"
                max-width="320px"
                class="mb-2" />

                <h4>In manutenzione, <br> Ripassa più tardi</h4>
                <!-- <p>La biblioteca è al momento in manutenzione.</p> -->
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'error_maintenance'
}
</script>

<style lang="scss" scoped>
.error-maintenance-view {
}
</style>