<template>
    <div class="policies-view">
        <bxs-toolbar
        sticky
        color="background">
            <h5 class="text-center flex-1">Prima di continuare</h5>
        </bxs-toolbar>

        <section>
            <bxs-layout>
                <p>Per usare la nostra web-app devi accettare le seguenti normative e condizioni di <b>Leila Italia</b></p>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <!-- <bxs-collapse-item class="mb-ui">
                    <template #label>
                        <bxs-icon
                        width="30"
                        name="check"></bxs-icon>
                        <h5>Privacy policy</h5>
                    </template>
                </bxs-collapse-item> -->

                <!-- <bxs-collapse-item class="mb-ui">
                    <template #label>
                        <bxs-icon
                        width="30"
                        name="check"></bxs-icon>
                        <h5>Cookie policy</h5>
                    </template>

                    <div>
                        <p>Al momento non usiamo cookie di terze parti.</p>
                    </div>
                </bxs-collapse-item> -->

                <bxs-list>
                    <bxs-list-item
                    href="https://leila-bologna.it/regolamento"
                    spacer
                    prepend-icon="check"
                    append-icon="arrow-up-right">Prestiti Policy</bxs-list-item>
                </bxs-list>

                <bxs-btn
                append-icon="arrow-right"
                between
                block
                color="success"
                light
                min-height="50"
                class="text-uppercase"
                @click="accept">Accetta e continua</bxs-btn>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'policies',
    methods: {
        accept () {
            localStorage.setItem('accept_policies', true)
            this.$router.push('/')
        }
    }
}
</script>