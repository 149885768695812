<template>
    <div class="order-row flex nowrap">
        <div>
            <bxs-figure
            ratio="1"
            width="80"
            class="bxs-pointer mb-ui mx-auto"
            @click="$router.push('/products/adladna')"></bxs-figure>
        </div>

        <div class="flex-1 px-ui">
            <div class="bxs-truncated mb-ui">
                <p class="text-500">Lorem ipsum, dolor sit amet</p>
            </div>

            <!--  -->
            <div>
                <div class="mb-2">
                    <p class="text-mute">Stato</p>
                    <p class="text-uppercase">Lorem ipsum dolor</p>
                </div>

                <!-- <div class="mb-2">
                    <p class="text-mute">Data richiesta</p>
                    <p>{{ $filters.toHumanDate(new Date()) }}</p>
                </div> -->

                <div>
                    <p class="text-mute">Periodo di prestito</p>

                    <p>
                        <span>{{ $filters.toHumanDate(new Date()) }}</span>
                        <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                        <span>{{ $filters.toHumanDate(new Date()) }}</span>
                    </p>
                </div>
            </div>

            <!--  -->
            <div>
                <div class="mt-ui">
                    <p class="text-mute text-uppercase">Note</p>
                    <div>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequatur maxime quia fugit tempore veniam voluptates veritatis quaerat at, eveniet, quibusdam iusto dolor in itaque culpa error odit illum exercitationem ex.</div>
                </div>

                <div class="mt-ui">
                    <p class="text-mute text-uppercase">Risposte alle tue domande</p>
                    <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem totam laboriosam optio aut cupiditate delectus similique illum inventore minus quod hic tempora, sunt animi iure recusandae odit, non unde veniam!</div>
                </div>
            </div>

            <!--  -->
            <div>
                <!-- <div class="text-right">
                    <bxs-btn
                    to="/me/orders/dinwd"
                    color="primary"
                    text
                    append-icon="chevron-right">Annulla prestito</bxs-btn>
                </div> -->

                <bxs-list>
                    <bxs-list-item
                    spacer
                    text-color="primary"
                    append-icon="close">Annulla prestito</bxs-list-item>
                </bxs-list>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'order_row'
}
</script>

<style lang="scss" scoped>
    .order-row {
        border-bottom: var(--thickness) solid var(--divider-color);

        &:only-child,
        &:last-child {
            border-bottom: 0;
        }
    }
</style>