<template>
    <div class="me-orders-view">
        <bxs-top-nav>La tua recensione</bxs-top-nav>

        <bxs-data-lazy
        :key="key"
        v-model="data_page"
        :call="() => $leila.me.getOrder($route.params.id)">
            <section>
                <bxs-layout>
                    <bxs-card class="pa-ui mb-ui">
                        <bxs-row>
                            <bxs-col cols="2">
                                <bxs-figure
                                ratio="1"
                                :src="$filters.toStaticFile(data_page.product.img.url)"
                                max-width="320"
                                class="bxs-pointer mb-ui mx-auto"
                                @click="$router.push('/products/' + data_page.product.id)"></bxs-figure>
                            </bxs-col>

                            <bxs-col
                            cols="10"
                            class="pl-ui">
                                <div class="bxs-truncated mb-ui">
                                    <h6 v-html="data_page.product.name"></h6>
                                </div>

                                <div>
                                    <div class="mb-2">
                                        <p class="mb-1 text-mute">Stato</p>
                                        <p class="text-uppercase">{{ $filters.toHumanOrderStatus(data_page.status) }}</p>
                                    </div>

                                    <div class="mb-2">
                                        <p class="mb-1 text-mute">Data richiesta</p>
                                        <p>{{ $filters.toHumanDate(data_page.created_at) }}</p>
                                    </div>

                                    <div class="mb-2">
                                        <p class="mb-1 text-mute">Periodo di prestito richiesto</p>

                                        <p>
                                            <span>{{ $filters.toHumanDate(data_page.loan.from_date) }}</span>
                                            <bxs-icon name="arrow-right" class="mx-2"></bxs-icon>
                                            <span>{{ $filters.toHumanDate(data_page.loan.to_date) }}</span>
                                        </p>
                                    </div>
                                </div>

                                <div
                                v-if="data_page.note"
                                class="mt-ui">
                                    <div class="mb-1">
                                        <p class="text-mute">Note</p>
                                    </div>

                                    <div v-html="$filters.mdToHtml(data_page.note)"></div>
                                </div>

                                <div
                                v-if="data_page.note_answers"
                                class="mt-ui">
                                    <div class="mb-1">
                                        <h6>Risposte alle tue domande</h6>
                                    </div>

                                    <div v-html="data_page.note_answers"></div>
                                </div>
                            </bxs-col>
                        </bxs-row>
                    </bxs-card>

                    <div class="mt-ui">
                        <div v-if="data_page.is_reviewable && !completed">
                            <bxs-row class="mb-2">
                                <bxs-col cols="12" ds="6">
                                    <bxs-title>Il tuo feedback</bxs-title>
                                    <small class="text-mute">Vota selezionando le stelline e scrivi il tuo messaggio</small>
                                </bxs-col>

                                <bxs-col
                                cols="12"
                                ds="6"
                                :class="{ 'text-right': !$store.state.is_mobile }">
                                    <bxs-icon
                                    v-for="n in 5"
                                    :key="n"
                                    width="30"
                                    height="30"
                                    :name="model.rating >= n || hover_star === n ? 'star-fill' : 'star'"
                                    class="text-secondary mr-3 bxs-pointer"
                                    @click="model.rating = n"
                                    @mouseenter="hover_star = n"
                                    @mouseleave="hover_star = null" />
                                </bxs-col>
                            </bxs-row>

                            <bxs-textarea-field
                            v-model="model.message"
                            name="message" />

                            <bxs-btn
                            block
                            color="primary"
                            light
                            :loading="loading"
                            :disabled="loading || !model.message"
                            @click="submit">Invia recensione</bxs-btn>
                        </div>

                        <div
                        v-else
                        class="text-center mt-ui">
                            <bxs-title
                            v-if="!data_page.is_reviewable"
                            class="mb-2">Hai già recensito questo oggetto!</bxs-title>
                            <bxs-title class="mb-1">Grazie per la tua recensione. Buona condivisione!</bxs-title>
                            <bxs-btn
                            text
                            prepend-icon="chevron-left"
                            @click="$router.push('/me/orders')">Ritorna ai prestiti</bxs-btn>
                        </div>
                    </div>

                    <div class="mt-ui">
                        <help-section />
                    </div>
                </bxs-layout>
            </section>
        </bxs-data-lazy>
    </div>
</template>

<script>
export default {
    name: 'me_order_create_review',
    data () {
        return {
            key: 1,
            data_page: null,
            //
            error: null,
            completed: null,
            loading: false,
            //
            model: {
                message: null,
                rating: 1
            },
            hover_star: null
        }
    },
    methods: {
        async submit () {
            this.error = null
            this.loading = true
            this.$store.commit('set_is_loading', true)

            try {
                await this.$leila.me.createProductReview(this.data_page.product.id, this.model)
                // this.$router.replace('/me/orders')
                this.completed = true
            } catch (err) {
                this.error = err.message || err.name || err
            } finally {
                this.loading = false
                this.$store.commit('set_is_loading', false)
            }
        }
    }
}
</script>