<template>
    <section class="bxs-pointer">
        <bxs-layout>
            <bxs-btn
            to="/search"
            outlined
            color="primary"
            block
            between
            min-height="60px"
            append-icon="chevron-right">Cerca tra gli oggetti</bxs-btn>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'to_products_section'
}
</script>