<template>
    <div class="bxs-list">
        <ul>
            <slot />
        </ul>
    </div>
</template>

<script>
export default {
    name: 'bxs-list'
}
</script>

<style lang="scss">
.bxs-list {
    position: relative;
}
</style>

<style lang="scss" scoped>
.bxs-list {
    position: relative;

    > ul {
        position: relative;

        > li {}
    }
}
</style>