<template>
    <div class="home-view">
        <bxs-toolbar
        depressed
        color="background">
            <img
            :src="$filters.toStaticFile($store.state.library.logo)"
            :alt="`Leila biblioteca degli oggetti ${$store.state.library ? $store.state.library.name : ''} logo`"
            style="max-width: 80px;">

            <bxs-spacer></bxs-spacer>

            <div class="bxs-truncate">
                <bxs-btn
                v-if="!$leila.auth.me"
                light
                to="/signin"
                color="black">Accedi</bxs-btn>

                <p
                v-else
                class="h5 mb-0 bxs-link"
                @click="$router.push('/me').catch(() => {})">Ciao <span>{{ $leila.auth.me.name.split(' ')[0] }}</span> <span class="h4">&#9996;</span></p>
            </div>
        </bxs-toolbar>

        <!-- welcome -->
        <home-banner />

        <search-box />

        <!-- <section class="py-0 mb-ui">
            <bxs-layout style="overflow: hidden;">
                <bxs-marquee :counts="20">
                    <p class="text-900 text-italic h3">risparmio <span class="mx-1">.</span> sostenibilità <span class="mx-1">.</span> ottimizzazione <span class="mx-1">.</span> comunità <span class="mx-1">.</span></p>
                </bxs-marquee>
            </bxs-layout>
        </section> -->

        <!--  -->
        <!-- <to-products-section /> -->

        <!-- highlighted products -->
        <highlighted-products-section />

        <!-- <come-funziona-section /> -->

        <!-- <section>
            <bxs-layout style="overflow: hidden;">
                <bxs-marquee :counts="20">
                    <img
                    src="/img/icona-incentivazione-alla-comunito-leila-srl.png"
                    alt=""
                    style="width: 40px; opacity: 0.2;">

                    <img
                    src="/img/icona-ottimizzazione-delle-risorse-leila-srl.png"
                    alt=""
                    style="width: 40px; opacity: 0.2;">
                </bxs-marquee>
            </bxs-layout>
        </section> -->

        <!-- <section>
            <bxs-layout style="overflow: hidden;">
                <bxs-marquee :counts="20">
                    <p class="text-900 text-italic h3" style="opacity: 0.25;">risparmio <span class="mx-1">.</span> sostenibilità <span class="mx-1">.</span> ottimizzazione <span class="mx-1">.</span> comunità <span class="mx-1 mr-2">.</span></p>
                </bxs-marquee>
            </bxs-layout>
        </section> -->

        <headquarters-section />

        <!-- <section>
            <bxs-layout style="overflow: hidden;">
                <bxs-marquee :counts="20">
                    <img
                    src="/img/icona-risparmio-economico-leila-srl.png"
                    alt=""
                    style="width: 40px; opacity: 0.2;">

                    <img
                    src="/img/icona-sostenibilita-ambientale-leila-srl.png"
                    alt=""
                    style="width: 40px; opacity: 0.2;">
                </bxs-marquee>
            </bxs-layout>
        </section> -->

        <signin-section />

        <!-- categories -->
        <!-- <categories-section /> -->

        <!-- help -->
        <help-section />

        <!-- welcome -->
        <!-- <section>
            <bxs-layout>
                <h1>Lorem ipsum dolor sit</h1>
                <h2>Lorem ipsum dolor sit</h2>
                <h3>Lorem ipsum dolor sit</h3>
                <h4>Lorem ipsum dolor sit</h4>
                <h5>Lorem ipsum dolor sit</h5>
                <h6>Lorem ipsum dolor sit</h6>
            </bxs-layout>
        </section> -->

        <!-- checkoutbar -->
        <checkoutbar to="/checkout/loan" />
    </div>
</template>

<script>
import HomeBanner from '@/components/project/HomeBanner.vue'
import SearchBox from '@/components/project/SearchBox.vue'
// import ComeFunzionaSection from '@/components/project/ComeFunzionaSection.vue'

export default {
    name: 'home',
    components: {
        'home-banner': HomeBanner,
        'search-box': SearchBox
        // 'come-funziona-section': ComeFunzionaSection
    },
    data () {
        return {
            loading: false,
            headquarters: []
        }
    }
}
</script>
