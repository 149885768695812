<template>
    <div class="me-view">
        <bxs-top-nav>Il tuo profilo</bxs-top-nav>

        <bxs-data-lazy
        ref="data"
        v-model="item"
        :call="() => $leila.me.get()">
            <section class="mt-ui">
                <bxs-layout>
                    <div class="text-center bxs-truncate mb-ui">
                        <h3 class="text-ui text-400 mb-0 text-none-transform">{{ item.name }}</h3>
                        <p class="text-mute">{{ item.email }}</p>
                    </div>

                    <div
                    v-if="!$store.state.library.is.auto_membership"
                    class="text-center mb-ui">
                        <bxs-chip
                        :outlined="!item.is.active_card"
                        :class="{ 'text-primary': !item.is.active_card }">
                            <span class="text-500">{{ item.is.active_card ? 'Tessera attiva' : 'Tessera scaduta' }}</span>
                            <bxs-icon
                            v-if="item.is.active_card"
                            name="check"
                            class="ml-2" />
                        </bxs-chip>
                    </div>

                    <div v-if="!$store.state.library.is.auto_membership">
                        <ul class="flex justify-center text-center">
                            <li class="text-center mr-12">
                                <h5 class="mb-0">{{ item.my_product_count || 0 }}</h5>
                                <small>Condivisi</small>
                            </li>

                            <li class="text-center mr-12">
                                <h5 class="mb-0">{{ item.product_borrowed_count || 0 }}</h5>
                                <small>In prestito</small>
                            </li>

                            <li class="text-center">
                                <h5 class="mb-0">
                                    <span v-if="item.my_product_count">
                                        <span v-if="item.my_product_count - item.product_borrowed_count <= 0">0</span>
                                        <span v-else>{{ (item.my_product_count - item.product_borrowed_count) || 0 }}</span>
                                    </span>
                                    <span v-else>0</span>
                                </h5>
                                <small>Disponibili</small>
                            </li>
                        </ul>
                    </div>

                    <div
                    v-if="$leila.auth.is_admin && $store.state.ghost_user"
                    class="text-center mt-ui">
                        <bxs-btn @click="deselectUserAuth">Rimuovi impersonificazione utente</bxs-btn>
                    </div>
                </bxs-layout>
            </section>

            <section>
                <bxs-layout>
                    <bxs-list>
                        <bxs-list-item
                        v-if="$leila.auth.is_admin"
                        append-icon="chevron-right"
                        spacer
                        @click="modal_users.on = true">Impersonifica utente</bxs-list-item>

                        <bxs-list-item
                        append-icon="chevron-right"
                        spacer
                        to="/me/orders">I miei prestiti</bxs-list-item>

                        <bxs-list-item
                        append-icon="chevron-right"
                        spacer
                        to="/me/products">I miei oggetti</bxs-list-item>

                        <bxs-list-item
                        v-if="$leila.me.is.can_post_objects"
                        append-icon="chevron-right"
                        spacer
                        to="/me/product-edit">Carica il tuo oggetto</bxs-list-item>

                        <!-- v-if="!$store.state.library.is.auto_membership" -->
                        <bxs-list-item
                        v-if="$leila.env === 'development'"
                        append-icon="chevron-right"
                        spacer
                        to="/me/rinnovo-tessera">Rinnova la mia tessera</bxs-list-item>

                        <bxs-list-item
                        append-icon="chevron-right"
                        spacer
                        to="/password/recovery">Cambia password</bxs-list-item>

                        <bxs-list-item
                        append-icon="chevron-right"
                        spacer
                        to="/help">Bisogno di aiuto?</bxs-list-item>

                        <!-- <bxs-list-item
                        append-icon="chevron-right"
                        spacer
                        to="/policies">Prestiti policy</bxs-list-item> -->

                        <bxs-list-item
                        prepend-icon="chevron-left"
                        @click="signout">Esci</bxs-list-item>
                    </bxs-list>
                </bxs-layout>
            </section>
        </bxs-data-lazy>

        <!-- checkoutbar -->
        <checkoutbar to="/checkout/loan" />

        <!-- users -->
        <bxs-menu
        v-if="$leila.auth.is_admin"
        v-model="modal_users.on"
        title="Cerca utenti"
        solo>
            <template #header>
                <bxs-text-field
                v-model="modal_users.keywords"
                placeholder="Cerca utente (email, ID)"
                name="keywords"
                hide-form
                clearable
                hide-details
                class="ma-0 pa-0 mt-2"
                @input="searchUser">
                    <template #prepend>
                        <bxs-icon
                        name="search"
                        width="1.25em"></bxs-icon>
                    </template>
                </bxs-text-field>
            </template>

            <bxs-list
            v-for="user in modal_users.items"
            :key="user.id">
                <bxs-list-item @click="selectUserAuth(user)">
                    <div class="flex-1 py-2">
                        <h6 class="mb-1">{{ user.name }}</h6>
                        <p class="text-mute">{{ user.email }}</p>
                        <small class="text-mute">{{ user.origin_id }}</small>
                    </div>
                </bxs-list-item>
            </bxs-list>
        </bxs-menu>
    </div>
</template>

<script>
export default {
    name: 'me',
    data () {
        return {
            item: null,
            modal_users: {
                on: false,
                loading: false,
                keywords: null,
                items: []
            }
        }
    },
    methods: {
        searchUser () {
            if (this.modal_users.keywords.length < 3) return

            this.$leila.users.list({
                pagination: false,
                select: ['card_number', 'email', 'is', 'name', 'origin_id'],
                sort: { name: 1 },
                filters: {
                    keywords: this.modal_users.keywords
                }
            }).then((docs) => {
                this.modal_users.items = docs
            })
        },
        async selectUserAuth (user) {
            sessionStorage.setItem('lel_ghost_user', user.id)
            this.$router.go()
            this.$toast.success('Impersonificazione attivata')
        },
        deselectUserAuth () {
            sessionStorage.removeItem('lel_ghost_user')
            this.$router.go()
            this.$toast.success('Impersonificazione disattivata')
        },
        async signout () {
            await this.$leila.auth.signout()
            sessionStorage.removeItem('lel_ghost_user')
            this.$router.go(-1)
        }
    }
}
</script>