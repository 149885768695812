<template>
    <div class="regolamento-view">
        <bxs-top-nav>Regolamento</bxs-top-nav>

        <div
        v-html="$store.state.library.regolamento"
        class="wysiwyg"></div>
    </div>
</template>

<script>
export default {
    name: 'regolamento'
}
</script>