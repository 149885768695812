<template>
    <div class="signin-view">
        <bxs-top-nav>Accedi</bxs-top-nav>

        <section>
            <bxs-layout>
                <bxs-form
                ref="form"
                @submit="submit"
                class="mb-ui">
                    <bxs-text-field
                    v-model="email"
                    name="email"
                    required
                    label="Email"
                    class="mb-grid-gutter" />

                    <bxs-text-field
                    v-model="password"
                    required
                    label="Password"
                    type="password"
                    name="password"
                    class="mb-grid-gutter">
                        <template #append="{ on, value }">
                            <bxs-btn
                            v-on="on"
                            text
                            small>{{ !value ? 'show' : 'hide' }}</bxs-btn>
                        </template>
                    </bxs-text-field>

                    <bxs-alert
                    v-if="error"
                    type="error"
                    class="mb-grid-gutter">{{ error }}</bxs-alert>

                    <div>
                        <bxs-btn
                        block
                        color="primary"
                        light
                        :disabled="loading"
                        :loading="loading"
                        @click="$refs.form.submit()">Accedi</bxs-btn>
                    </div>
                </bxs-form>

                <bxs-list>
                    <bxs-list v-if="$store.state.library.is.signupable">
                        <bxs-list-item
                        append-icon="chevron-right"
                        spacer
                        to="/signup">Registrati</bxs-list-item>
                    </bxs-list>

                    <bxs-list>
                        <bxs-list-item
                        append-icon="chevron-right"
                        spacer
                        to="/password/recovery">Imposta o recupera la password</bxs-list-item>
                    </bxs-list>

                    <bxs-list-item
                    append-icon="arrow-top-right"
                    link
                    spacer
                    :href="$store.state.library ? $store.state.library.links.how_work : ''">Vuoi iniziare a condividere? Entra a far parte della comunità!</bxs-list-item>
                </bxs-list>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'signin',
    data () {
        return {
            email: null,
            password: null,
            error: null,
            loading: false
        }
    },
    methods: {
        async submit () {
            this.error = null
            this.loading = true

            try {
                await this.$leila.auth.signInWithEmailAndPassword(this.email, this.password)
                this.$leila.me.get()
                this.$leila.checkout.get()

                const last_page = localStorage.getItem('lel_last_page')
                const nps = ['/signin', '/password/recovery', '/password/reset']

                this.$router.push(last_page && !nps.includes(last_page) ? last_page : '/')
            } catch (err) {
                // this.error = 'E-mail o password non validi'
                if (err.status_code === 409) throw new Error('E-mail o password non validi')
                throw new Error(err.message || err.code)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>