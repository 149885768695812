import moment from 'moment'
import { parse } from 'marked'

moment.locale('it')
const url = new URL(window.location.href)

const d = {
    toHumanDate(value, f = 'L') {
        return value && moment(value).isValid() ? moment(value).format(f) : (value || '--')
    },
    toHumanDateNextMonth (value, f = 'L') {
        return value && moment(value).isValid() ? moment(value).add(1, 'M').format(f) : (value || '--')
    },
    toHumanCalendar(value) {
        return value ? moment(value).calendar() : (value || '--')
    },
    mdToHtml: v => parse(v),
    isMajorOfNow(value) {
        if (!value || !moment(value).isValid()) return value
        return moment(value).diff(moment()) >= 0
    },
    truncate(v, l = 115) {
        if (!v) return v
        return v.substring(0, l) + '...'
    },
    highlight(v, k) {
        if (!v || !k) return v
        return v.replace(k, '<span class="text-highlight">' + k + '</span>')
    },
    toHumanOrderStatus(v) {
        let _v = v

        if (v === 'checkout') _v = 'Checkout in corso'
        else if (v === 'pending') _v = 'Attesa conferma'
        else if (v === 'approved') _v = 'Approvato'
        else if (v === 'extended') _v = 'Esteso'
        else if (v === 'cancelled') _v = 'Annullato'
        else if (v === 'denied') _v = 'Negato'
        else if (v === 'returned') _v = 'Restituito'

        return _v
    },
    toStaticFile(v) {
        if (!v) return v
        // const env = process.env.NODE_ENV
        // if (v.startsWith('http')) v = v.replace('http://localhost:8080/', '')
        return `${process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API : url.origin}/${v}`
    },
    toStaticFileMaster (v) {
        if (!v) return v
        // const env = process.env.NODE_ENV
        // if (v.startsWith('http')) v = v.replace('http://localhost:8080/', '')
        return `${process.env.VUE_APP_API_MASTER}/${v}`
    }
}

export default d