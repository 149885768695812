<template>
    <div class="error-404-view">
        <bxs-top-nav>Ops</bxs-top-nav>

        <section class="py-ui">
            <bxs-layout class="text-center">
                <bxs-figure
                src="/img/elefante.webp"
                max-width="320px"
                class="mb-2" />

                <h2 class="mb-1">Error 404</h2>
                <p class="mb-8">Pagina non trovata o in costruzione</p>

                <bxs-btn
                block
                uppercase
                to="/">Riparti</bxs-btn>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'error_404'
}
</script>