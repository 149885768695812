<template>
    <div class="checkout-cb-view--pp">
        PP
    </div>
</template>

<script>
export default {
    name: 'checkout_paypal_cb'
}
</script>