import Resource from './BaseResource'

class Library extends Resource {
    constructor(config, tools, version, path) {
        super(config, tools, version, path)
    }

    async getRenewalsCards(q) {
        const res = await this.tools.http.get(`${this.api}/renewals/cards`, q)
        return res
    }
}

export default Library
