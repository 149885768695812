<template>
    <div
    sticky-container
    class="search-view">
        <bxs-top-nav>Cerca</bxs-top-nav>

        <section v-sticky>
            <bxs-layout>
                <!-- :disabled="busy" -->
                <bxs-text-field
                v-model="keywords"
                hide-form
                clearable
                :loading="busy"
                autofocus
                name="keywords"
                label="Cosa stai cercando?">
                    <template #prepend>
                        <bxs-icon
                        name="search"
                        width="1.25em"></bxs-icon>
                    </template>
                </bxs-text-field>
            </bxs-layout>
        </section>

        <!-- items -->
        <section>
            <bxs-layout>
                <ul v-if="docs.length > 0">
                    <li
                    v-for="product in docs"
                    :key="product.id"
                    class="bxs-pointer mb-ui">
                        <product-row :item="product" />
                    </li>
                </ul>

                <div
                v-else-if="!!keywords && keywords.length >= 3"
                class="text-center">
                    <h6>Impossibile trovare</h6>
                    <h6 class="mb-1">"{{ keywords }}"</h6>
                    <p class="text-mute">Prova a effettuare nuovamente la ricerca selezionando un'altra categoria.</p>
                </div>
            </bxs-layout>
        </section>

        <!-- categories -->
        <categories-2-section />

        <!-- headquarters -->
        <headquarters-section />

        <!-- checkoutbar -->
        <checkoutbar to="/checkout/loan" />
    </div>
</template>

<script>
import ProductRow from '@/components/project/ProductRow'

export default {
    name: 'search',
    components: {
        'product-row': ProductRow
    },
    data () {
        return {
            keywords: null,
            docs: [],
            busy: false
        }
    },
    watch: {
        keywords () {
            this.getDocs()
        }
    },
    methods: {
        getDocs () {
            if (!this.keywords || this.keywords.length < 3) return

            this.busy = true

            this.$http.get('v1/catalogs/products/search', {
                keywords: this.keywords
            }).then((docs) => {
                this.docs = docs
                this.busy = false
            }).catch(() => {
                this.busy = false
            })
        }
    }
}
</script>