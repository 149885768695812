<template>
    <bxs-data-lazy
    v-model="categories"
    :call="() => $leila.get('v1/categories')">
        <section class="categories-section">
            <bxs-layout>
                <div class="flex align-center mb-ui">
                    <h5>Cerca tra le categorie</h5>
                    <bxs-spacer></bxs-spacer>
                    <span
                    class="text-primary"
                    @click="$router.push('/catalogs')">
                        <small class="mr-2">vedi tutte</small>
                        <bxs-icon
                        name="chevron-right"
                        width="1rem"></bxs-icon>
                    </span>
                </div>

                <bxs-carousel
                :items="categories"
                freemode
                :items-space-between="40"
                scrollbar>
                    <template #item="{ item }">
                        <bxs-chip :to="`/catalogs/${item.slug}`">{{ item.name }}</bxs-chip>
                    </template>
                </bxs-carousel>

                <!-- <bxs-chip-group>
                    <bxs-chip
                    v-for="(item, i) in categories"
                    :key="i"
                    :to="`/catalogs/${item.slug}`">{{ item.name }}</bxs-chip>
                </bxs-chip-group> -->
            </bxs-layout>
        </section>
    </bxs-data-lazy>
</template>

<script>
export default {
    name: 'categories-section',
    data () {
        return {
            categories: []
        }
    }
}
</script>

<style lang="scss" scoped>
.categories-section {
    user-select: none !important;
}
</style>