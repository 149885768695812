<template>
    <section class="pressing-section">
        <bxs-layout>
            <bxs-btn
            to="/help"
            color="info"
            block
            light
            between
            min-height="60px"
            append-icon="chevron-right">Se ne hai bisogno urgente prova a telefonarci e ci mettiamo d'accordo</bxs-btn>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'pressing-section'
}
</script>