import store from '../store'

export function CheckMaintenance(roles, { to, from, router, next }) {
    const leila = window.$leila

    if (store.state.library_domain) {
        return leila.libraries.doc(store.state.library_domain).then((doc) => {
            this.$store.commit('set_library', doc)
            next()
        }).catch(() => {
            return next('/errors/maintenance')
        })
    }

    return next()
}

export function auth(roles, { to, from, router, next }) {
    const leila = window.$leila
    // const policies = localStorage.getItem('accept_policies')

    if (typeof roles === 'string') {
        roles = roles.split(',').map(i => i.toUpperCase())
    }

    console.log('mid auth', leila.auth.me)

    if (!leila.auth.is_expired) {
        // if (!policies && to.name !== 'policies') {
        //     return next('/policies')
        // }

        if (to.name === 'signin' || to.name === 'signup') {
            return next('/')
        }
    } else {
        if (to.name !== 'signin' && to.name !== 'signup' && to.name !== 'password_recovery' && to.name !== 'password_reset') {
            return next('/signin')
        }
    }

    return next()
}

export function checkout(roles, { to, from, router, next }) {
    const leila = window.$leila

    if (typeof roles === 'string') {
        roles = roles.split(',').map(i => i.toUpperCase())
    }

    if (!leila.checkout.exists) {
        return next('/')
    }

    return next()
}

export function checkoutLoan(roles, { to, from, router, next }) {
    const leila = window.$leila

    if (typeof roles === 'string') {
        roles = roles.split(',').map(i => i.toUpperCase())
    }

    if (!leila.checkout.is_set_loan) {
        return next('/checkout/loan')
    }

    return next()
}

export function policies({ to, from, router, next }) {
    const policies = localStorage.getItem('accept_policies')

    if (!policies && to.name !== 'policies') {
        return next('/policies')
    }

    return next()
}

export function saveQuery({ to, from, router, next }) {
    if (!to.query) return router.push('/').catch(() => { })
    return next()
}

//

export function nextFactory(context, middlewares, index) {
    const subSequentMiddleware = middlewares[index]
    if (!subSequentMiddleware) return context.next

    return (...parameters) => {
        context.next(...parameters)
        const nextMiddleware = nextFactory(context, middlewares, index + 1)
        subSequentMiddleware({ ...context, next: nextMiddleware })
    }
}