import { createApp, reactive } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import Api from './assets/libs/Api'
import Http from './assets/libs/Http'
import components from './components'
import directives from './directives'
import filters from './filters'
import { useToast } from 'vue-toastification'
import { createHead, VueHeadMixin } from '@unhead/vue'
// import 'element-plus/dist/index.css'

const url = new URL(window.location.href)
// console.log(url)
store.commit('set_library_domain', process.env.NODE_ENV !== 'production' || url.origin.includes('-stage') ? 'bologna' : url.hostname.split('.')[0])

const app = createApp(App)
const http = new Http({
    base_url: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API : url.origin,
    app_id: 'leila-saas',
    prefix: 'leila-s',
    access_token_key: 'lel_a_t'
})

const Leila = new Api({
    base_url: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API : url.origin,
    app_id: 'leila-saas',
    prefix: 'leila-s'
})

const head = createHead()

app.config.globalProperties.$filters = filters
app.config.globalProperties.$leila = reactive(Leila)
app.config.globalProperties.$http = http
window.$http = app.config.globalProperties.$http
window.$leila = app.config.globalProperties.$leila
components(app)
directives(app)
app.mixin(VueHeadMixin)

app.config.globalProperties.$toast = useToast()

app.config.errorHandler = (err, vm, info) => {
    console.log('error handler', err, vm, info)
    // const payload = Object.values(err.payload || {})
    let message = err.message || err.code || err

    if (err.code === 'resource.validation') {
        message += Object.values(err.fields)
    }

    vm.$toast.error(String(message))
}

app.use(head).use(store).use(router).mount('#app')

// Promise.all([
//     store.dispatch('get_access')
// ]).then(() => {
//     return store.dispatch('get_me_checkout').then(() => {
//         app.use(router).mount('#app')
//     })
// })
