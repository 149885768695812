<template>
    <div class="signup-view">
        <bxs-top-nav>Registrati</bxs-top-nav>

        <section>
            <bxs-layout>
                <div class="mb-4">
                    <div
                    v-if="$store.state.library.signup_disclaimer"
                    v-html="$store.state.library.signup_disclaimer"></div>
                </div>

                <bxs-form
                ref="form"
                @submit="submit"
                class="mb-ui">
                    <div class="mb-ui">
                        <bxs-title class="mb-ui">Informazioni base</bxs-title>

                        <bxs-text-field
                        v-model="model.name.first_name"
                        name="name.first_name"
                        required
                        label="Nome"
                        class="mb-grid-gutter" />

                        <bxs-text-field
                        v-model="model.name.last_name"
                        name="name.last_name"
                        required
                        label="Cognome"
                        class="mb-grid-gutter" />

                        <bxs-text-field
                        v-model="model.email"
                        name="email"
                        required
                        :rules="['email']"
                        label="Email"
                        class="mb-grid-gutter" />

                        <!-- <bxs-text-field
                        v-model="model.password"
                        required
                        label="Password"
                        type="password"
                        name="password"
                        class="mb-grid-gutter">
                            <template #append="{ on, value }">
                                <bxs-btn
                                v-on="on"
                                text
                                small>{{ !value ? 'show' : 'hide' }}</bxs-btn>
                            </template>
                        </bxs-text-field> -->

                        <bxs-text-field
                        v-model="model.phone"
                        name="phone"
                        required
                        label="Telefono"
                        class="mb-grid-gutter" />

                        <bxs-text-field
                        v-model="model.fiscalcode"
                        name="fiscalcode"
                        required
                        label="Matricola universitaria"
                        class="mb-grid-gutter" />
                    </div>

                    <div class="mb-ui">
                        <bxs-title class="mb-ui">Domicilio</bxs-title>

                        <div>
                            <bxs-text-field
                            v-model="model.address.address"
                            name="address.address"
                            required
                            label="Via e numero civico"
                            class="mb-grid-gutter" />

                            <bxs-text-field
                            v-model="model.address.city"
                            name="address.city"
                            required
                            label="Città"
                            class="mb-grid-gutter" />

                            <bxs-text-field
                            v-model="model.address.province"
                            name="address.province"
                            required
                            label="Provincia (XY)"
                            class="mb-grid-gutter" />

                            <bxs-text-field
                            v-model="model.address.postalcode"
                            name="address.postalcode"
                            required
                            label="Codice postale"
                            class="mb-grid-gutter" />

                            <!-- <bxs-text-field
                            v-model="model.address.nation"
                            name="address.nation"
                            required
                            label="Nazione"
                            class="mb-grid-gutter" /> -->
                        </div>
                    </div>

                    <bxs-alert
                    v-if="error"
                    type="error"
                    class="mb-grid-gutter">{{ error }}</bxs-alert>

                    <div>
                        <bxs-btn
                        block
                        color="primary"
                        light
                        :disabled="loading"
                        :loading="loading"
                        @click="$refs.form.submit()">Registrati</bxs-btn>
                    </div>
                </bxs-form>

                <bxs-list>
                    <bxs-list>
                        <bxs-list-item
                        append-icon="chevron-right"
                        spacer
                        to="/signin">Hai già un profilo? Accedi</bxs-list-item>
                    </bxs-list>
                </bxs-list>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'signup',
    data () {
        return {
            model: {
                name: {
                    first_name: null,
                    last_name: null
                },
                email: null,
                password: null,
                phone: null,
                fiscalcode: null,
                address: {
                    address: null,
                    city: null,
                    province: null,
                    postalcode: null,
                    nation: null
                }
            },
            error: null,
            loading: false
        }
    },
    methods: {
        async submit () {
            this.error = null
            this.loading = true

            try {
                await this.$leila.auth.signup(this.model)
                this.$router.push('/signin')
                this.$toast.success('Registrazione completata!')
            } catch (err) {
                this.error = err
                throw new Error(err)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>