<template>
    <div v-if="$store.state.library && $store.state.library.socials">
        <bxs-btn
        v-if="$store.state.library.socials.ig"
        :href="$store.state.library.socials.ig"
        target="_blank"
        color="black"
        light
        pill
        width="20"
        height="20"
        class="mr-ui">
            <bxs-icon name="ig"></bxs-icon>
        </bxs-btn>

        <bxs-btn
        v-if="$store.state.library.socials.tw"
        :href="$store.state.library.socials.tw"
        target="_blank"
        color="black"
        light
        pill
        width="20"
        height="20"
        class="mr-ui">
            <bxs-icon name="tw"></bxs-icon>
        </bxs-btn>

        <bxs-btn
        v-if="$store.state.library.socials.yt"
        :href="$store.state.library.socials.yt"
        target="_blank"
        color="black"
        light
        pill
        width="20"
        height="20"
        class="mr-ui">
            <bxs-icon name="yt"></bxs-icon>
        </bxs-btn>

        <bxs-btn
        v-if="$store.state.library.socials.fb"
        :href="$store.state.library.socials.fb"
        color="black"
        light
        pill
        width="20"
        height="20">
            <bxs-icon name="fb"></bxs-icon>
        </bxs-btn>
    </div>
</template>