<template>
    <section class="home-banner pt-0">
        <bxs-layout
        class="bxs-border-radius"
        style="background-image: url(../img/leila.jpg)">
            <div class="home-banner-overlay"></div>

            <div class="t text-center text-white">
                <h1>
                    <span class="h2">Condividere</span>
                    <span class="h3 mx-2 text-italic text-300">is the new</span>
                    <span class="h2">possedere</span>
                </h1>
            </div>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'home_banner'
}
</script>

<style lang="scss">
@import '@/assets/styles/conf.scss';

.home-banner {
    position: relative;
    overflow: hidden;
    border-radius: var(--figure-border-radius);
    height: 180px;
    // background-color: yellow;

    .bxs-layout {
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        overflow: hidden;
    }

    .t {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;

        h1 {
            line-height: 80%;
            margin-top: -15px;
        }
    }

    .bxs-r-home-banner {
        overflow: hidden;
    }
}

.home-banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none;
    z-index: 0;
    transform: scale(1.1);

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(map-get($colors, 'black'), 0.3);
    }
}
</style>